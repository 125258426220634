<template>
  <div v-if="!isLoading">
    <fragment
      v-for="(car, indCar) in reserva.car_shop"
      :key="indCar"
    >
      <ServiceCar
        v-if="car.entity === 'cars'"
        :pos="indCar"
        :reserva="reserva"
        :item="car"
        :states="states"
        :conditions="conditions"
        :user="user"
        @updateStateReservaItem="updateStateReservaItem"
      />
    </fragment>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
} from '@mdi/js'

import ServiceCar from './ServiceCar.vue'

export default {
  components: {
    ServiceCar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    reserva: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    conditions: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
      },
      isDialogVisibleDelete: false,
      isDialogVisible: false,
      item: {},
      itemId: null,
      loading: false,
      reservando: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      states: [],
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.getStates()
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
    getStates() {
      this.axios
        .get('state_reservations?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.states = res.data.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateStateReservaItem(state) {
      this.$emit('updateStateReserva', state)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
